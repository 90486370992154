<template>
  <section class="ecommerce-application">
    <b-card>
      <b-form v-if="slaStatuses" @submit.prevent="saveSla">
        <div v-for="(slaStatus, ssk) in slaManagement" :key="`${ssk}_status_sla`" class="row mt-1 pb-2"
          :class="{ 'border-bottom': (ssk + 1) < slaManagement.length }">
          <div class="col-12">
            <div class="row">
              <div class="col-md-4 col-12">
                <label class="mb-0">Status</label>
                <b-select :options="slaStatuses" v-model="slaManagement[ssk].status" :disabled="true" class="custom-disabled" />
              </div>
              <div class="col-md-4 col-12">
                <label class="mb-0">Time Unit</label>
                <b-select :options="slaTimeUnits" v-model="slaManagement[ssk].time_unit" />
              </div>
              <div class="col-md-4 col-12">
                <label class="mb-0">Time Value</label>
                <b-input type="number" :min="0" :step="1" v-model="slaManagement[ssk].time_value" />
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-end mt-3">
          <div class="col-auto">
            <b-button variant="primary" type="submit">Save</b-button>
          </div>
        </div>
      </b-form>
    </b-card>
  </section>
</template>

<script>
import { hideLoader, showLoader } from '@/@core/comp-functions/ui/app';
import { isEmpty } from '@/utilities';
import { mapActions } from 'vuex';

const slaTimeUnits = ['DAYS', 'MINUTES', 'HOURS'];

export default {
  name: "SlaSettings",
  components: {

  },
  data: () => ({
    slaTimeUnits,
    slaStatuses: [],
    slaManagement: [],
  }),
  computed: {

  },
  methods: {
    ...mapActions('store', ['getSlaManagement', 'saveSlaManagement']),
    isEmpty,
    saveSla() {
      showLoader();
      this.saveSlaManagement(this.slaManagement).then(r => {
        this.slaManagement = r
        hideLoader();
      }).catch(e => {
        hideLoader();
      })
    },
    getSla() {
      showLoader()
      this.getSlaManagement().then(r => {
        this.slaManagement = r.sla_management
        this.slaStatuses = r.sla_management_statuses
        hideLoader();
      }).catch(e => {
        hideLoader();
      })
    }
  },
  mounted() {
    this.getSla()
  }

}
</script>
<style>
.custom-disabled:disabled{
  color: #000 !important;
}
</style>